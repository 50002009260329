import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./ProductShowcaseAnimatedComponent.css";
// import productImage from "../../assets/product-showcase-images/product-display-main-image.png";
import productImage from "../../assets/product-showcase-images/Second-Image-Test.png"
import productImageBackdrop from "../../assets/product-showcase-images/product-image-backdrop-updated.png";
import YUL7PuffArrow from "../../assets/product-showcase-images/YUL7Puff";
import MiniYULPuffArrow from "../../assets/product-showcase-images/MiniYUL7PuffArrow";


const ProductShowcaseAnimatedComponent = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const springTransition = {
    type: "spring",
    stiffness: 100,
    damping: 65,
    mass: 1,
    delay: 0,
  };

  useEffect(() => {
    if (inView) {
      controls.start({
        scale: 1,
        opacity: 1,
        transition: { ...springTransition, ease: "easeInOut" },
      });
    } else {
      controls.start({
        scale: 1.1,
        opacity: 0.8,
        transition: { ...springTransition, ease: "easeInOut" },
      });
    }
  }, [controls, inView]);

  return (
    <>
      <div className="psc-animated-component-main-container">
        <div className="psc-animated-backdrop-imageContainer">
          <img src={productImageBackdrop} />
        </div>
        {/* <div className="psc-animated-backdrop-imageContainer">
          <img src={productImageBackdrop} />
        </div> */}
        <div className="psc-animated-component-container" ref={ref}>
          <motion.div
            className="psc-animated-component-image"
            initial={{ scale: 1.1, opacity: 0.8 }}
            animate={controls}
          >
            <img src={productImage} alt="Product Showcase" />
          </motion.div>
          <div className="psb-content">
            <div className="psb-mini-yul-puff-container">
              <div className="psb-mini-yul-puff-text-container">
                <p className="psb-mini-yul-puff-text">Mini YUL7 Puff</p>
              </div>
              <div className="psb-mini-puff-arrow-container">
                <MiniYULPuffArrow className="psb-arrow" />
              </div>
            </div>
            <div className="psb-yul-puff-container">
              <div className="psb-puff-arrow-container">
                <YUL7PuffArrow className="psb-puff-arrow" />
              </div>
              <div className="psb-yul-puff-text-container">
                <p className="psb-yul-puff-text">YUL7 Puff</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductShowcaseAnimatedComponent;
