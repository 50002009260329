const YouTubeButtonIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6812 9.60015C31.6812 9.60015 31.3688 7.3939 30.4062 6.42515C29.1875 5.15015 27.825 5.1439 27.2 5.0689C22.725 4.7439 16.0063 4.7439 16.0063 4.7439H15.9937C15.9937 4.7439 9.275 4.7439 4.8 5.0689C4.175 5.1439 2.8125 5.15015 1.59375 6.42515C0.63125 7.3939 0.325 9.60015 0.325 9.60015C0.325 9.60015 0 12.1939 0 14.7814V17.2064C0 19.7939 0.31875 22.3876 0.31875 22.3876C0.31875 22.3876 0.63125 24.5939 1.5875 25.5626C2.80625 26.8376 4.40625 26.7939 5.11875 26.9314C7.68125 27.1751 16 27.2501 16 27.2501C16 27.2501 22.725 27.2376 27.2 26.9189C27.825 26.8439 29.1875 26.8376 30.4062 25.5626C31.3688 24.5939 31.6812 22.3876 31.6812 22.3876C31.6812 22.3876 32 19.8001 32 17.2064V14.7814C32 12.1939 31.6812 9.60015 31.6812 9.60015ZM12.6938 20.1501V11.1564L21.3375 15.6689L12.6938 20.1501Z"
        fill="#F17EAE"
      />
    </svg>
  );
};

export default YouTubeButtonIcon;
