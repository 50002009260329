const YulLogoWhite = () => {
  return (
    <svg
      width="69"
      height="24"
      viewBox="0 0 69 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.528529 0.0145264C0.513823 0.0204086 0.437352 0.0321732 0.363822 0.0439377C0.0785261 0.0821724 0.0344082 0.243938 0.213821 0.590996C0.275586 0.711584 1.38442 2.52335 2.67854 4.61747C5.54621 9.2557 7.92858 13.1292 7.99328 13.2557C8.0227 13.3086 8.06681 13.4263 8.09329 13.5204C8.14034 13.6763 8.14623 14.0645 8.16093 18.441C8.17564 22.9645 8.17858 23.1969 8.22858 23.2998C8.26093 23.3645 8.32564 23.4292 8.39035 23.4616C8.49329 23.5116 8.61094 23.5145 10.1904 23.5145C11.8404 23.5145 11.8845 23.5145 11.9992 23.4528C12.0757 23.4145 12.1374 23.3528 12.1757 23.2763C12.2345 23.1645 12.2345 23.0881 12.2492 18.3822C12.2639 13.9292 12.2668 13.591 12.3168 13.4498C12.3815 13.2616 12.5904 12.9145 14.0374 10.6028C17.6816 4.78217 20.1434 0.832172 20.1904 0.723349C20.264 0.558643 20.3669 0.179232 20.3434 0.164526C20.3316 0.158644 20.1845 0.117468 20.0169 0.0762901L19.711 -0.000179291H18.1757C16.7139 -0.000179291 16.6286 0.00276184 16.4463 0.0586433C16.1757 0.140997 15.9904 0.320408 15.761 0.708643C15.661 0.876291 15.2374 1.60864 14.8169 2.33805C14.2198 3.37335 11.6845 7.73511 10.5051 9.76159L10.3286 10.0645L10.1992 9.86453C10.1315 9.75276 8.83447 7.59394 7.31975 5.06747C5.80503 2.541 4.50503 0.408644 4.43444 0.32629C4.35797 0.238056 4.24032 0.146879 4.13738 0.0968781L3.96973 0.0145264L2.26383 0.0086441C1.32559 0.00570297 0.546176 0.0086441 0.528529 0.0145264Z"
        fill="white"
      />
      <path
        d="M21.4847 0.0263462C21.3288 0.0586987 21.2611 0.105759 21.217 0.211639C21.1582 0.349876 21.1552 16.5499 21.2141 16.9558C21.5347 19.1793 22.5023 20.9558 24.0729 22.2087C25.0817 23.0146 26.1317 23.494 27.67 23.8558L28.2847 23.9999H29.6141C30.7171 23.9999 30.97 23.9911 31.0935 23.9558C31.1788 23.9293 31.4406 23.8705 31.6818 23.8234C33.4377 23.4675 34.82 22.7322 35.9465 21.5469C37.1436 20.294 37.8465 18.7205 38.0877 16.7705C38.1965 15.8705 38.2054 15.2499 38.2054 7.79399C38.2054 1.81752 38.1995 0.517523 38.1671 0.391052C38.1201 0.208698 37.9848 0.0734043 37.8171 0.0381107C37.7553 0.0263462 37.0171 0.0145817 36.1759 0.0145817C34.4936 0.0145817 34.4436 0.0204639 34.2847 0.18811C34.2347 0.238111 34.1789 0.332228 34.1583 0.396935C34.1259 0.491053 34.1171 1.9587 34.0994 7.95576C34.0789 16.0058 34.073 16.3381 33.923 17.094C33.7671 17.8763 33.3906 18.5734 32.8318 19.1028C32.2435 19.6646 31.4818 20.0381 30.6171 20.194C30.0729 20.2911 29.0435 20.2616 28.3965 20.1293C27.0376 19.8528 25.9582 18.8469 25.4847 17.4146C25.3611 17.044 25.3082 16.8146 25.2288 16.294C25.1817 15.9822 25.1788 15.3734 25.1641 8.20576L25.1464 0.455757L25.0729 0.308699C25.0141 0.191051 24.97 0.146935 24.8523 0.08811L24.7053 0.0145817L23.1464 0.00869942C22.2905 0.00869942 21.5405 0.0145817 21.4847 0.0263462Z"
        fill="white"
      />
      <path
        d="M40.9346 0.029398C40.7287 0.0735149 40.6316 0.138222 40.564 0.288221L40.4993 0.426456V11.7353V23.0441L40.5728 23.1912C40.6669 23.3765 40.7846 23.4559 41.0375 23.5C41.1816 23.5235 42.9081 23.5294 47.6317 23.5235L54.0288 23.5147L54.1641 23.4529C54.2788 23.4 54.3082 23.3676 54.3552 23.247C54.4082 23.1117 54.4111 23 54.4111 21.6323C54.4111 20.2647 54.4082 20.1529 54.3552 20.0176C54.3082 19.897 54.2788 19.8647 54.1641 19.8117L54.0288 19.75L49.6405 19.7412L45.2493 19.7353V10.2412C45.2493 4.48528 45.2375 0.664692 45.2199 0.538221C45.1817 0.249985 45.1287 0.155869 44.9611 0.0793972C44.8228 0.0146923 44.8022 0.0146923 42.9405 0.00881004C41.9052 0.005867 41.0022 0.0176334 40.9346 0.029398Z"
        fill="white"
      />
      <path
        d="M54.7847 0.0440731C54.6259 0.0940723 54.5494 0.158779 54.4818 0.308779C54.4318 0.417603 54.4259 0.532309 54.4288 1.94113C54.4288 3.68525 54.4288 3.69407 54.6494 3.81466C54.7141 3.8529 54.8612 3.89407 54.973 3.91172C55.0994 3.92937 56.8112 3.94113 59.6112 3.94113C62.0495 3.94113 64.0436 3.94995 64.0436 3.96172C64.0436 4.06172 63.2848 5.97643 58.3142 18.4411C57.3436 20.8764 56.5259 22.9441 56.4994 23.0382C56.4406 23.2441 56.4583 23.3941 56.5553 23.4558C56.6906 23.5441 57.0671 23.5617 58.7348 23.5529L60.3818 23.5441L60.523 23.4735C60.5995 23.4382 60.6965 23.3617 60.7377 23.3088C60.7759 23.2558 60.8877 23.0088 60.9818 22.7617C61.0789 22.5117 61.2465 22.0911 61.3612 21.8235C61.473 21.5558 62.0377 20.1676 62.6171 18.7352C65.2289 12.2647 66.3407 9.52937 67.6172 6.41172C68.4642 4.3529 68.6319 3.86172 68.7789 3.01466C68.8819 2.41466 68.9054 2.05878 68.8878 1.32643C68.8642 0.376425 68.8172 0.202896 68.5495 0.0793667L68.4113 0.0146618L61.6612 0.00877762C56.0436 0.00583649 54.8906 0.00877762 54.7847 0.0440731Z"
        fill="white"
      />
    </svg>
  );
};

export default YulLogoWhite;
