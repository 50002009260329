import "./MobileInstagramCard.css";
import PlayIcon from "../../assets/icon-components/PlayIcon/PlayIcon";
const MobileInstagramCard = ({
  image,
  viewCount,
  height,
  width,
  countLeft,
  countRight,
  url,
}) => {
  const viewCountContainerClassName = `yul7_mobile_instagram-crd_view_count_container ${
    countLeft ? "left-aligned" : ""
  } ${countRight ? "right-aligned" : ""}`;
  const handleCardClick = () => {
    window.open(url, "_blank");
  };
  return (
    <div
      className="yul7_mbl_instagram_crd"
      style={{
        backgroundImage: `url(${image})`,
        height,
        width,
        objectFit: "cover",
      }}
      onClick={handleCardClick}
    >
      <div className={viewCountContainerClassName}>
        {/* <img src={playicon} height={16} width={16} /> */}
        <PlayIcon height={16} width={16}/>
        <p>{viewCount}M</p>
      </div>
    </div>
  );
};

export default MobileInstagramCard;
