import "./MobileProductShowcaseSingle.css";

const MobileProductShowcaseSingle = ({ image, arrow: Arrow, cardText }) => {
  return (
    <div className="mobile-product-showcase-single">
      <div className="mobile-product-showcase-single-image-container">
        <img src={image} alt="" className="mobile-product-showcase-img" />
      </div>
      <div className="mobile-product-showcase-single-image-container-backdrop">
        <img src={image} alt="" className="mobile-product-showcase-img" />
      </div>
      <div className="mobile-product-showcase-bottom-information">
        <Arrow />
        <p className="mobile-product-showcase-single-text">{cardText}</p>
      </div>
    </div>
  );
};

export default MobileProductShowcaseSingle;
