import "./MobileNavigationBar.css";

import BurgerIcon from "../../../assets/icon-components/BurgerIcon/BurgerIcon";
import Yul7Logo from "../../../assets/icon-components/YulLogo/Yul7Logo";

const MobileNavigationBar = ({ onButtonClick, scrollTo, refs }) => {
  return (
    <div className="mobile-nav-container">
      <div onClick={() => scrollTo(refs.homeRef)} className="mobile-nav-logo-container">
        <Yul7Logo />
      </div>
      <div
        className="mobile-nav-burger-container"
        onClick={onButtonClick}
        style={{ cursor: "pointer" }}
      >
        <BurgerIcon />
      </div>
    </div>
  );
};

export default MobileNavigationBar;
