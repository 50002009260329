const Yul7Logo = ({ height = 24, width = 69 }) => {
  return (
    <svg
      width={width} 
      height={height}
      viewBox="0 0 69 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_181_779)">
        <path
          d="M0.426476 0.0146484C0.41177 0.0205307 0.335299 0.0322952 0.26177 0.0440598C-0.0235245 0.0822945 -0.0676421 0.244061 0.11177 0.591118C0.173534 0.711706 1.28236 2.52347 2.57648 4.61759C5.44412 9.25583 7.82648 13.1294 7.89118 13.2558C7.92059 13.3088 7.96471 13.4264 7.99118 13.5205C8.03824 13.6764 8.04412 14.0646 8.05883 18.4411C8.07353 22.9646 8.07648 23.197 8.12648 23.2999C8.15883 23.3646 8.22353 23.4294 8.28824 23.4617C8.39118 23.5117 8.50883 23.5146 10.0882 23.5146C11.7382 23.5146 11.7824 23.5146 11.8971 23.4529C11.9735 23.4146 12.0353 23.3529 12.0735 23.2764C12.1324 23.1646 12.1324 23.0882 12.1471 18.3823C12.1618 13.9294 12.1647 13.5911 12.2147 13.4499C12.2794 13.2617 12.4882 12.9146 13.9353 10.6029C17.5794 4.7823 20.0412 0.832294 20.0882 0.723471C20.1618 0.558765 20.2647 0.179354 20.2412 0.164648C20.2294 0.158766 20.0824 0.11759 19.9147 0.0764122L19.6088 -5.72205e-05H18.0735C16.6118 -5.72205e-05 16.5265 0.00288391 16.3441 0.0587654C16.0735 0.141119 15.8882 0.32053 15.6588 0.708765C15.5588 0.876413 15.1353 1.60877 14.7147 2.33818C14.1177 3.37347 11.5824 7.73524 10.4029 9.76171L10.2265 10.0646L10.0971 9.86465C10.0294 9.75288 8.73236 7.59406 7.21765 5.06759C5.70295 2.54112 4.40295 0.408766 4.33236 0.326412C4.25589 0.238178 4.13824 0.147001 4.0353 0.0970001L3.86765 0.0146484L2.16177 0.00876617C1.22353 0.00582504 0.444123 0.00876617 0.426476 0.0146484Z"
          fill="#1C1C1C"
        />
        <path
          d="M21.3824 0.0264683C21.2265 0.0588207 21.1588 0.105881 21.1147 0.211761C21.0559 0.349998 21.0529 16.55 21.1118 16.9559C21.4324 19.1794 22.4 20.9559 23.9706 22.2088C24.9794 23.0147 26.0294 23.4941 27.5676 23.8559L28.1824 24H29.5118C30.6147 24 30.8676 23.9912 30.9912 23.9559C31.0765 23.9294 31.3382 23.8706 31.5794 23.8235C33.3353 23.4676 34.7176 22.7324 35.8441 21.5471C37.0412 20.2941 37.7441 18.7206 37.9853 16.7706C38.0941 15.8706 38.1029 15.25 38.1029 7.79412C38.1029 1.81764 38.0971 0.517645 38.0647 0.391174C38.0176 0.20882 37.8824 0.0735264 37.7147 0.0382328C37.6529 0.0264683 36.9147 0.0147038 36.0735 0.0147038C34.3912 0.0147038 34.3412 0.020586 34.1824 0.188232C34.1324 0.238234 34.0765 0.33235 34.0559 0.397057C34.0235 0.491175 34.0147 1.95882 33.9971 7.95588C33.9765 16.0059 33.9706 16.3382 33.8206 17.0941C33.6647 17.8765 33.2882 18.5735 32.7294 19.1029C32.1412 19.6647 31.3794 20.0382 30.5147 20.1941C29.9706 20.2912 28.9412 20.2618 28.2941 20.1294C26.9353 19.8529 25.8559 18.8471 25.3824 17.4147C25.2588 17.0441 25.2059 16.8147 25.1265 16.2941C25.0794 15.9824 25.0765 15.3735 25.0618 8.20588L25.0441 0.455879L24.9706 0.308821C24.9118 0.191174 24.8676 0.147057 24.75 0.088232L24.6029 0.0147038L23.0441 0.00882149C22.1882 0.00882149 21.4382 0.0147038 21.3824 0.0264683Z"
          fill="#1C1C1C"
        />
        <path
          d="M40.8324 0.029398C40.6265 0.0735149 40.5294 0.138222 40.4618 0.288221L40.3971 0.426456V11.7353V23.0441L40.4706 23.1912C40.5647 23.3765 40.6824 23.4559 40.9353 23.5C41.0794 23.5235 42.8059 23.5294 47.5294 23.5235L53.9265 23.5147L54.0618 23.4529C54.1765 23.4 54.2059 23.3676 54.2529 23.247C54.3059 23.1117 54.3088 23 54.3088 21.6323C54.3088 20.2647 54.3059 20.1529 54.2529 20.0176C54.2059 19.897 54.1765 19.8647 54.0618 19.8117L53.9265 19.75L49.5382 19.7412L45.1471 19.7353V10.2412C45.1471 4.48528 45.1353 0.664692 45.1177 0.538221C45.0794 0.249985 45.0265 0.155869 44.8588 0.0793972C44.7206 0.0146923 44.7 0.0146923 42.8382 0.00881004C41.8029 0.005867 40.9 0.0176334 40.8324 0.029398Z"
          fill="#1C1C1C"
        />
        <path
          d="M54.6824 0.0440731C54.5235 0.0940723 54.4471 0.158779 54.3794 0.308779C54.3294 0.417603 54.3235 0.532309 54.3265 1.94113C54.3265 3.68525 54.3265 3.69407 54.5471 3.81466C54.6118 3.8529 54.7588 3.89407 54.8706 3.91172C54.9971 3.92937 56.7088 3.94113 59.5088 3.94113C61.9471 3.94113 63.9412 3.94995 63.9412 3.96172C63.9412 4.06172 63.1824 5.97643 58.2118 18.4411C57.2412 20.8764 56.4235 22.9441 56.3971 23.0382C56.3383 23.2441 56.3559 23.3941 56.453 23.4558C56.5883 23.5441 56.9647 23.5617 58.6324 23.5529L60.2794 23.5441L60.4206 23.4735C60.4971 23.4382 60.5941 23.3617 60.6353 23.3088C60.6735 23.2558 60.7853 23.0088 60.8794 22.7617C60.9765 22.5117 61.1441 22.0911 61.2588 21.8235C61.3706 21.5558 61.9353 20.1676 62.5147 18.7352C65.1265 12.2647 66.2383 9.52937 67.5147 6.41172C68.3618 4.3529 68.5294 3.86172 68.6765 3.01466C68.7794 2.41466 68.803 2.05878 68.7853 1.32643C68.7618 0.376425 68.7147 0.202896 68.4471 0.0793667L68.3088 0.0146618L61.5588 0.00877762C55.9412 0.00583649 54.7883 0.00877762 54.6824 0.0440731Z"
          fill="#1C1C1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_181_779">
          <rect width="68.8015" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Yul7Logo;
