const YUL7PuffArrow = () => {
  return (
    <svg
      width="169"
      height="80"
      viewBox="0 0 169 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.706 25.9133C150.091 50.9038 132.724 71.2216 101.622 77.4084C81.5818 81.3948 58.957 76.0953 41.806 65.3352C27.4572 56.3332 23.3222 40.1353 17.5085 25.6515C15.3099 20.1739 15.621 12.4793 12.8688 7.7119C12.1203 6.41539 3.6539 17.1024 1.91975 19.0579C1.3493 19.7012 7.63312 10.1487 8.65944 7.02339C14.2501 -10.0009 24.5234 21.213 33.0032 19.4779"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default YUL7PuffArrow;
