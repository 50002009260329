import "./LottieSecondSection.css";
import animationData from "../../assets/rotating-section-animation.json";
import animationDataDesktop from "../../assets/rotating-section-animations/Rotating Animation Desktop.json"
import animationDataMobile from "../../assets/rotating-section-animations/Rotating Animation Mobile.json";

import Lottie from "lottie-react";
const LottieSecondSection = () => {
  return (
    <>
      <div className="yul-excitement-main-container">
        <div className="yul-excitement-text-container">
          <h1>Excitement begins with <br/> small things!</h1>
          <p>
            Through innovative products and inspiring experiences, we strive to
            make every day a celebration of beauty and self-love. Yul7 is
            dedicated to redefining the beauty industry by creating innovative
            tools and experiences that inspire joy and excitement even in the
            simplest things.
          </p>
        </div>
        <div className="yul-excitement-animation-container">
          <div className="yul-excitement-animation-dekstop">
            <Lottie animationData={animationDataDesktop} />
          </div>
          <div className="yul-excitement-animation-mobile">
            <Lottie animationData={animationDataMobile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LottieSecondSection;
