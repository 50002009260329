import "./SiteInfoModal.css";
import CloseIcon from "../../../assets/icon-components/CloseIcon/CloseIcon";

const SiteInfoModal = ({
  siteInformation,
  headerText,
  onCloseButtonClick,
  isOpen,
}) => {
  if (!isOpen) return null;
  return (
    <div className="yul-site-info-modal-outer-container">
      <div className="yul-site-info-modal-container">
        <div className="site-info-modal-header">
          <div className="site-info-header-title-container">
            <p className="site-info-modal-header-text">{headerText}</p>
          </div>
          <div
            className="site-info-modal-close-button-container"
            onClick={onCloseButtonClick}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="site-info-modal-content-body">
          {siteInformation.map((item, index) => {
            return (
              <InfoModalSingleLine
                key={index}
                title={item.title}
                body={item.body}
                number={index + 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const InfoModalSingleLine = ({ title, body, number }) => {
  return (
    <>
      <div className="site-info-modal-single-line">
        <div className="site-info-modal-single-line-number">
          <p>{number}.&nbsp; </p>
        </div>
        <p style={{ textAlign: "justify" }}>
          <span className="site-info-modal-single-line-title">{title}</span>{" "}
          {body}
        </p>
      </div>
    </>
  );
};

export default SiteInfoModal;
