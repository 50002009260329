import React, { useRef, useEffect } from "react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import YulLogoWhite from "../../../assets/icon-components/YulLogoWhite/YulLogoWhite";
import "./MobileNavigationBottomSheet.css";
import MobileNavigationSingleButton from "./MobileNavigationSingleButton/MobileNavigationSingleButton";

const MobileNavigationBottomSheet = ({
  isOpen,
  refs,
  onClose,
  scrollToBlock,
}) => {
  const controls = useAnimation();
  const sheetRef = useRef(null);
  const y = useMotionValue(0);

  const navigationValues = [
    { name: "BRAND", ref: refs.brandRef },
    { name: "PRODUCT", ref: refs.productRef },
    { name: "YUL7 IN ACTION", ref: refs.instagramRef },
    { name: "LET'S TALK", ref: refs.contactRef },
  ];

  useEffect(() => {
    if (isOpen) {
      controls.start({ y: 0 });
    } else {
      controls.start({ y: "100%" });
    }
  }, [isOpen, controls]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sheetRef.current &&
        !sheetRef.current.contains(event.target) &&
        isOpen &&
        !event.target.closest(".mobile-nav-burger-container")
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleDrag = (event, info) => {
    if (info.offset.y >= 0) {
      y.set(info.offset.y);
    } else {
      y.set(0);
    }
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.y > 120 || info.velocity.y > 500) {
      controls.start({ y: "100%" });
      onClose();
    } else {
      controls.start({ y: 0 });
    }
  };

  return (
    <motion.div
      ref={sheetRef}
      className={`mbl-nvg-popup-container ${isOpen ? "open" : ""}`}
      style={{ y }}
      initial={{ y: "100%" }}
      animate={controls}
      transition={{
        type: "spring",
        damping: 60,
        stiffness: 900,
      }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      dragElastic={0.2}
      dragMomentum={false}
    >
      <div className="mbl-nvg-popup-inner-container">
        <div className="mbl-nvg-bar-container">
          <div className="mbl-nvg-bar-separator"></div>
        </div>
        <div
          className="mbl-nvg-logo-container"
          onClick={() => scrollToBlock(refs.homeRef)}
        >
          <YulLogoWhite />
        </div>
        <div className="mbl-nvg-options-container">
          {navigationValues.map((item, index) => (
            <MobileNavigationSingleButton
              key={index}
              buttonText={item.name}
              onClick={() => scrollToBlock(item.ref)}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default MobileNavigationBottomSheet;
