const YulUpwardsArrow = () => {
  return (
    <svg
      width="12"
      height="25"
      viewBox="0 0 12 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00513 23.9208C5.00514 16.9067 5.57035 9.41063 4.84653 2.41337C4.63385 0.357345 4.62809 1.02787 4.14865 2.82576C3.72362 4.41963 -0.451037 13.5395 1.9757 7.53645C2.26141 6.82969 3.97917 1.20025 4.0852 1.42999C5.78302 5.1087 8.15376 8.22662 11.0006 11.0734"
        stroke="#1C1C1C"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default YulUpwardsArrow;
