import './App.css';
import LandingPage from './LandingPage';

function App() {


  return (
    <div className="yulu7_main_container">
      <LandingPage />
    </div>
  );
}

export default App;
