const TestNaverIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3944 0.0949707H26.8004C29.7244 0.0949707 32.0993 2.46996 32.0993 5.39391V26.7999C32.0993 29.7239 29.7244 32.0989 26.8004 32.0989H5.3944C2.47044 32.0989 0.095459 29.7239 0.095459 26.7999V5.39391C0.095459 2.46996 2.47044 0.0949707 5.3944 0.0949707Z"
        fill="#F17EAE"
      />
      <path
        d="M8.97241 8.77337V5.11745C8.97241 4.6697 9.3345 4.30762 9.78224 4.30762C10.23 4.30762 10.5921 4.6697 10.5921 5.11745V8.77337C10.5921 11.8063 13.0605 14.2748 16.0935 14.2748C19.1264 14.2748 21.5949 11.8063 21.5949 8.77337V5.11745C21.5949 4.6697 21.957 4.30762 22.4047 4.30762C22.8524 4.30762 23.2145 4.6697 23.2145 5.11745V8.77337C23.2145 12.7018 20.018 15.8944 16.0935 15.8944C12.1689 15.8944 8.97241 12.6979 8.97241 8.77337Z"
        fill="white"
      />
      <path
        d="M27.2561 27.5046H25.4223L22.2765 23.6423V27.5046H20.1584V21.0026H21.9922L25.1381 24.9388V21.0026H27.2561V27.5046Z"
        fill="white"
      />
    </svg>
  );
};

export default TestNaverIcon;
