import background from "./cloud-background.png";
import hand from "./hand.png";
import rosePetals from "./rose-petals.png";
import cloudLayer from "./cloud-layer.png";
import backgroundMobile from "./cloud-background-mobile.png";
import handMobile from "./hand-mobile.png";
import rosePetalsMobile from "./rose-petals-mobile.png";
import cloudLayerMobile from "./cloud-layer-mobile.png";

import test from "../../assets/footer-images/footer-desktop.png"

export const heroImages = {
  background,
  hand,
  rosePetals,
  cloudLayer,
  backgroundMobile,
  handMobile,
  rosePetalsMobile,
  cloudLayerMobile,
  test
};
