import Yul7Logo from "../../../assets/icon-components/YulLogo/Yul7Logo";
import "./NavigationBar.css";

const NavigationBar = ({ scrollToBlock, refs }) => {
  return (
    <nav className="navigator">
      <div className="navigator_inner_container">
        <div className="navigator_lt">
          <div onClick={() => scrollToBlock(refs.brandRef)}>
            <p>BRAND</p>
          </div>
          <div onClick={() => scrollToBlock(refs.productRef)}>
            <p>PRODUCT</p>
          </div>
        </div>
        <div onClick={() => scrollToBlock(refs.homeRef)}>
          <Yul7Logo />
        </div>
        <div className="navigator_rt">
          <div onClick={() => scrollToBlock(refs.instagramRef)}>
            <p>YUL7 IN ACTION</p>
          </div>
          <div onClick={() => scrollToBlock(refs.contactRef)}>
            <p>LET'S TALK</p>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
