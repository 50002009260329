import instagramImage1 from './instagram-img-01.jpg';
import instagramImage2 from './instagram-img-02.jpg';
import instagramImage3 from './instagram-img-03.jpg';
import instagramImage4 from './instagram-img-04.jpg';
import instagramImage5 from './instagram-img-05.jpg';

export const instagramImages = {
    instagramImage1,
    instagramImage2,
    instagramImage3,
    instagramImage4,
    instagramImage5
}