const MiniYULPuffArrow = () => {
  return (
    <svg
      width="166"
      height="78"
      viewBox="0 0 166 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00009 76.944C8.96135 60.241 20.4272 46.981 39.3317 42.8714C55.756 39.3009 72.2092 40.7728 88.666 38.0799C107.261 35.0372 125.933 27.4269 141.49 16.9029C146.794 13.3154 152.024 7.28828 157.521 4.53975"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M145.808 2.41016C151.436 2.41016 157.846 1.6394 162.845 1.34537C165.443 1.1925 165.312 0.532403 164.146 1.58199C160.363 4.98673 155.929 11.4445 154.327 16.2521"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default MiniYULPuffArrow;
