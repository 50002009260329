const YulUpwardsArrow1 = () => {
  return (
    <svg
      width="13"
      height="23"
      viewBox="0 0 13 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41211 22.2834C6.92643 16.3992 6.7542 10.7579 6.64232 4.87329C6.636 4.54095 6.49264 1.07046 6.34383 1.24165C5.08279 2.69231 2.5113 6.53731 1.5 8C0.421204 9.56031 5.96585 3.16637 5.9449 1.26955C5.93732 0.583876 6.76401 2.58753 6.90178 2.85072C7.72437 4.42222 10.6818 6.85412 12 8"
        stroke="#1C1C1C"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default YulUpwardsArrow1;
