import "./InstagramBlock.css";

import InstagramCard from "../../components/SingleInstagramCard/InstagramCard";
import { instagramImages } from "../../assets/instagram-images";
import InstagramMobileCards from "../../components/InstagramMobileCards/InstagramMobileCards";
import { useState, useEffect } from "react";

const InstagramBlock = () => {
  const instagramCardInformation = [
    {
      image: instagramImages.instagramImage4,
      viewCount: 1.5,
      height: 300,
      width: 191.88,
      url: "https://www.instagram.com/reel/C4KHEGavOiE/?igsh=MWo3eHFzc3V4YjdyYg==",
    },
    {
      image: instagramImages.instagramImage2,
      viewCount: 6,
      height: 360,
      width: 230.25,
      url: "https://www.instagram.com/reel/C4nLa5MPEGE/?igsh=NnAya2hxbm54YWl4",
    },
    {
      image: instagramImages.instagramImage1,
      viewCount: 33.7,
      height: 400,
      width: 255.84,
      url: "https://www.instagram.com/reel/C3aAYJWPqzB/?igsh=MXBrbzdxbW93Nzk4aQ==",
    },
    {
      image: instagramImages.instagramImage3,
      viewCount: 3.5,
      height: 360,
      width: 230.25,
      url: "https://www.instagram.com/reel/C3kUYz6vIt_/?igsh=bDl6N3lnMmVrZ2I1",
    },
    {
      image: instagramImages.instagramImage5,
      viewCount: 2.2,
      height: 300,
      width: 191.88,
      url: "https://www.instagram.com/reel/C3pSpugvwip/",
    },
  ];

  const instagramMobileCardInformation = [
    {
      image: instagramImages.instagramImage2,
      viewCount: 6,
      height: 200,
      width: 125,
      url: "https://www.instagram.com/reel/C4nLa5MPEGE/?igsh=NnAya2hxbm54YWl4",
    },
    {
      image: instagramImages.instagramImage1,
      viewCount: 33.7,
      height: 250,
      width: 154,
      url: "https://www.instagram.com/reel/C3aAYJWPqzB/?igsh=MXBrbzdxbW93Nzk4aQ==",
    },
    {
      image: instagramImages.instagramImage3,
      viewCount: 3.5,
      height: 200,
      width: 125,
      url: "https://www.instagram.com/reel/C3kUYz6vIt_/?igsh=bDl6N3lnMmVrZ2I1",
    },
  ];
  

  const [cardInfo, setCardInfo] = useState(instagramMobileCardInformation);

  const adjustDimensions = () => {
    const screenWidth = window.innerWidth;
  
    const widthFactor = 0.3;
    const newWidth = screenWidth * widthFactor;
  
    const adjustedCards = instagramMobileCardInformation.map(card => {
      const aspectRatio = card.height / card.width;
      const adjustedHeight = newWidth * aspectRatio;
  
      return {
        ...card,
        height: adjustedHeight,
        width: newWidth,
      };
    });
  
    setCardInfo(adjustedCards);
  };
  
  useEffect(() => {
    adjustDimensions();
    window.addEventListener('resize', adjustDimensions);
    
    return () => window.removeEventListener('resize', adjustDimensions);
  }, []);

  return (
    <div className="instagram_block">
      <div className="inst_blk_title_container">
        <h1>Join the Yul7 Movement</h1>
        <p>
          Long-lasting, full coverage perfection.That's what 33 million people
          can't stop talking about.
        </p>
      </div>
      <div className="instagram_cards_container">
        {instagramCardInformation.map((item, index) => (
          <InstagramCard
            key={index}
            image={item.image}
            viewCount={item.viewCount}
            height={item.height}
            width={item.width}
            url={item.url}
          />
        ))}
      </div>
      <div className="instagram_cards_container_mobile">
        <InstagramMobileCards
          cardInformation={instagramMobileCardInformation}
        />
      </div>
    </div>
  );
};

export default InstagramBlock;
