import "./MobileProductShowcase.css";
import miniYul from "../../assets/product-showcase-images/mini-yul-mobile.png";
import yul from "../../assets/product-showcase-images/yul-mobile.png";
import MobileProductShowcaseSingle from "./MobileProductShowcaseSingle/MobileProductShowcaseSingle";
import YulUpwardsArrow from "../../assets/product-showcase-images/YulUpwardsArrow";
import YulUpwardsArrow1 from "../../assets/product-showcase-images/YulUpwardsArrow1";

const MobileProductShowcase = () => {
    return(
        <div className="ps-mobile-container">
            <MobileProductShowcaseSingle arrow={YulUpwardsArrow} image={miniYul} cardText={"Mini YUL7 Puff"}/>
            <MobileProductShowcaseSingle arrow={YulUpwardsArrow1} image={yul} cardText={"YUL7 Puff"}/>
        </div>
    );
}

export default MobileProductShowcase;