import "./MobileNavigationSingleButton.css";

const MobileNavigationSingleButton = ({ buttonText, onClick }) => {
  return (
    <div className="mbl-nvg-single-btn-container" onClick={onClick}>
      <p>{buttonText}</p>
    </div>
  );
};

export default MobileNavigationSingleButton;
