import React, { useEffect, useState } from "react";
import "./HeroBlock.css";
import { heroImages } from "../../assets/hero-assets";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion, spring } from "framer-motion";

const HeroBlock = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const petalsControls = useAnimation();
  const handControls = useAnimation();
  const cloudControls = useAnimation();

  const springTransition = {
    type: "spring",
    stiffness: 100,
    damping: 65,
    mass: 1,
    delay: 0,
  };

  useEffect(() => {
    if (inView) {
      petalsControls.start({
        opacity: 1,
        scale: 1,
        transition: {
          ...springTransition,
          // duration: 1.5,
          ease: "easeOut",
          opacity: { duration: 1 },
        },
      });
      handControls.start({
        
        y: -18,
        opacity: 1,
        transition: {
          ...springTransition,
          // duration: 1.5,
          ease: "easeOut",
        },
      });
      cloudControls.start({
        scale: 1,
        opacity: 1,
        transition: {
          ...springTransition,
          // duration: 1.5,
          ease: "easeOut",
        },
      });
    } else {
      petalsControls.start({
        opacity: 0,
        scale: 1.2,
        transition: {
          duration: 1.5,
          ease: "easeInOut",
        },
      });
      handControls.start({
        y: 110,
        opacity: 1,
        transition: {
          ...springTransition,
          // duration: 1.5,
          ease: "easeInOut",
        },
      });
      cloudControls.start({
        scale: 1.2,
        opacity: 1,
        transition: {
          ...springTransition,
          // duration: 1.5,
          ease: "easeInOut",
        },
      });
    }
  }, [inView, petalsControls, handControls]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 750px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const getBackgroundImage = (desktopImage, mobileImage) => {
    return isMobile ? mobileImage : desktopImage;
  };

  return (
    <div className="yul-hero-block-container" ref={ref}>
      <div className="yul-hero-content">
        <motion.div
          className="yul-hero-background"
          style={{
            backgroundImage: `url(${getBackgroundImage(
              heroImages.background,
              heroImages.backgroundMobile
            )})`,
          }}
          animate={cloudControls}
          initial={{ scale: 1.2 }}
        />
        <motion.div
          className="yul-hero-petals"
          style={{
            backgroundImage: `url(${getBackgroundImage(
              heroImages.rosePetals,
              heroImages.rosePetalsMobile
            )})`,
          }}
          animate={petalsControls}
          initial={{ opacity: 0, scale: 1.3 }}
        />
        <motion.div
          className="yul-hero-hand"
          style={{
            backgroundImage: `url(${getBackgroundImage(
              heroImages.hand,
              heroImages.handMobile
            )})`,
          }}
          animate={handControls}
          initial={{ y: 110, opacity: 1 }}
        />
        <div
          className="yul-hero-cloud-effect"
          style={{
            backgroundImage: `url(${getBackgroundImage(
              heroImages.cloudLayer,
              heroImages.cloudLayerMobile
            )})`,
          }}
        />
        <div className="yul-hero-text-container">
          <div className="yul-hero-text-wrapper">
            <div className="yul-hero-header-text-container">
              <p className="yul-hero-header-text">ROLL GLOW GO</p>
            </div>
            <div className="yul-hero-sub-text-container">
              <p className="yul-hero-sub-text">
                Confidence that lasts as long as you do
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBlock;
