import "./YulNavigation.css";

import NavigationBar from "../DesktopNavigationBar/NavigationBar";
import MobileNavigationBar from "../MobileNavigationBar/MobileNavigationBar";

const YulNavigation = ({ onBurgerButtonClick, scrollToBlock, refs }) => {
  return (
    <>
      <div className="yuldesktop-nav-container">
        <NavigationBar scrollToBlock={scrollToBlock} refs={refs} />
      </div>
      <div className="yulmobile-nav-container">
        <MobileNavigationBar
          onButtonClick={onBurgerButtonClick}
          refs={refs}
          scrollTo={scrollToBlock}
        />
      </div>
    </>
  );
};

export default YulNavigation;
