import React from 'react';
import { motion } from 'framer-motion';

const AnimatedPath = ({ d, animate }) => {
  const pathVariants = {
    hidden: { pathLength: 0 },
    visible: { 
      pathLength: 1, 
      transition: { 
        duration: 2, 
        ease: "easeInOut"
      } 
    }
  };

  return (
    <motion.path
      d={d}
      stroke="#DAF0C7"
      strokeWidth="2"
      fill="none"
      variants={pathVariants}
      initial="hidden"
      animate={animate ? "visible" : "hidden"}
    />
  );
};

const ConnectingGreenLine = ({ firstLineVisible, secondLineVisible }) => {
  return (
    <svg
      width="432"
      height="942"
      viewBox="0 0 432 942"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <AnimatedPath 
        d="M9.27539 1C-24.5 269 40.3694 355.046 431 467" 
        animate={firstLineVisible}
      />
      <AnimatedPath 
        d="M422.725 475C456.5 743 391.631 829.046 1.00009 941" 
        animate={secondLineVisible}
      />
    </svg>
  );
};

export default ConnectingGreenLine;