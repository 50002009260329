import SiteInfoModal from "../SiteInfoModal/SiteInfoModal";

const TermsAndConditionModal = ({ onCloseButonClick, isOpen }) => {
  const termsAndConditions = [
    {
      title: "Acceptance of Terms",
      body: `By accessing or using the YUL7 website, you agree to be bound by these Terms of Use. If you do not agree with these terms, please do not use our website.`,
    },
    {
      title: "Use of the Website",
      body: `You may use our website for informational purposes only. As we do not offer direct purchasing through the website, all inquiries regarding products should be directed to us via email.`,
    },
    {
      title: "Product Inquiries and Purchases",
      body: `If you are interested in purchasing our products or have any questions, please contact us at yul7_official@naver.com. We will assist you with your order or any product-related inquiries through email communication.`,
    },
    {
      title: "Intellectual Property",
      body: `All content on our website, including text, images, and logos, is the property of YUL7 and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or otherwise use any content without our prior written consent.`,
    },
    {
      title: "Changes to These Terms",
      body: `We may update these Terms of Use from time to time. We will notify you of any significant changes by posting the new terms on our website.`,
    },
    {
      title: "Contact Us",
      body: `For any questions regarding these Terms of Use, please contact us at yul7_official@naver.com.`,
    },
  ];

  return (
    <SiteInfoModal
      siteInformation={termsAndConditions}
      headerText={"TERMS OF USE (이용약관)"}
      onCloseButtonClick={onCloseButonClick}
      isOpen={isOpen}
    />
  );
};

export default TermsAndConditionModal;
