import "./ContactBlock.css";
import { useState, useEffect } from "react";
import HeartIcon from "../../assets/icon-components/HeartIcon/HeartIcon";
import TestNaverIcon from "../../assets/icon-components/TestNaverIcon/TestNaverIcon";
import SocialButton from "../../components/SocialButton/SocialButton";
import NaverIconHere from "../../assets/icon-components/SocialMediaIcons/NaverIcon/NaverIconHere";
import YouTubeButtonIcon from "../../assets/icon-components/SocialMediaIcons/YouTubeIcon/YouTubeIcon";
import YouTubeButtonHoverIcon from "../../assets/icon-components/SocialMediaIcons/YouTubeHoverIcon/YouTubeButtonHoverIcon";
import InstagramButtonIcon from "../../assets/icon-components/SocialMediaIcons/InstagramIcon/InstagramButtonIcon";
import InstagramButtonHoverIcon from "../../assets/icon-components/SocialMediaIcons/InstagramButtonHoverIcon/InstagramHoverIcon";

const ContactBlock = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 750px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="contact-block-container">
      <div className="getin-touch-container">
        <h1>Get in Touch</h1>
        <HeartIcon />
        <p>
          We'd love to hear from you! Whether you have a question about our
          products, need assistance with an order, or simply want to share your
          Yul7 experience, we're here to help!
        </p>
      </div>

      <div className="contact-block-social-icons-container">
        <SocialButton
          RegularIcon={InstagramButtonIcon}
          HoverIcon={InstagramButtonHoverIcon}
          isMobile={isMobile}
          onClickUrl={"https://instagram.com/yul7_official"}
        />
        <SocialButton
          RegularIcon={TestNaverIcon}
          HoverIcon={NaverIconHere}
          isMobile={isMobile}
          onClickUrl={"https://smartstore.naver.com/yul7"}
        />
        <SocialButton
          RegularIcon={YouTubeButtonIcon}
          HoverIcon={YouTubeButtonHoverIcon}
          isMobile={isMobile}
          onClickUrl={"https://www.youtube.com/@yul7.official"}
        />
      </div>

      <div className="contact-block-phone-email-container">
        <div className="contact-block-phone">
          <p>Phone: +82-10-9158-7856</p>
        </div>
        <div className="contact-block-email">
          <p>Email: yul7_official@naver.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;
