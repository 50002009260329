import React, { useState, useEffect } from "react";
import "./FeatureCard.css";
import NewTextIconCard from "../TextIconCard/NewTextIconCard";
import GlowingImage from "../GlowingImage/GlowingImage";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function FeatureCard({ image, text, rightImage, onVisible }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.8, 
  });
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth > 750);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      // onVisible();
    }
  }, [controls, inView, onVisible]);

  const springTransition = {
    type: "spring",
    stiffness: 300,
    damping: 75,
    mass: 1,
    restDelta: 0.001
  };

  const slideUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ...springTransition,
        opacity: { duration: 1, ease: "easeOut" }
      },
    },
  };

  const slideUpTextVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ...springTransition,
        opacity: { duration: 1, ease: "easeOut" }
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, x: rightImage ? -50 : 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ...springTransition,
        opacity: { duration: 1.2, ease: "easeOut" },
        delay: 0.2
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ...springTransition,
        opacity: { duration: 0.8, ease: "easeOut" }
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="yul7_feauture_card_container"
      initial="hidden"
      animate={controls}
      variants={cardVariants}
    >
      {rightImage ? (
        <>
          <motion.div
            className="feature_crd_text_icon_container"
            variants={isLargeScreen ? textVariants : slideUpTextVariants}
          >
            <NewTextIconCard text={text} rightImage={rightImage} />
          </motion.div>
          <motion.div
            className="feature_crd_img_container"
            variants={slideUpVariants}
          >
            <GlowingImage image={image} />
          </motion.div>
        </>
      ) : (
        <>
          <motion.div
            className="feature_crd_img_container"
            variants={slideUpVariants}
          >
            <GlowingImage image={image} />
          </motion.div>
          <motion.div
            className="feature_crd_text_icon_container"
            variants={isLargeScreen ? textVariants : slideUpTextVariants}
          >
            <NewTextIconCard text={text} />
          </motion.div>
        </>
      )}
    </motion.div>
  );
}