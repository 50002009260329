import React, { useState } from "react";
import "./SocialButton.css";

const SocialButton = ({
  RegularIcon,
  HoverIcon,
  isMobile,
  onClickUrl
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconOuterStyle = {
    width: isMobile ? "48px" : "60px",
    height: isMobile ? "48px" : "60px",
    borderRadius: isMobile ? "18px" : "24px",
  };

  const iconStyle = {
    width: isMobile ? "24px" : "32px",
    height: isMobile ? "24px" : "32px",
  };

  const handleClick = () => {
    window.open(onClickUrl, "_blank");
  };

  return (
    <div
      className="social-button-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={iconOuterStyle}
      onClick={handleClick}
    >
      <div className="social-button-icon-container" style={iconStyle}>
        {isHovered ? <HoverIcon /> : <RegularIcon />}
      </div>
    </div>
  );
};

export default SocialButton;
