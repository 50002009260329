import SiteInfoModal from "../SiteInfoModal/SiteInfoModal";

const PrivacyPolicyModal = ({ onCloseButtonClick, isOpen }) => {
  const privacyPolicyData = [
    {
      title: "Introduction",
      body: `YUL7 ("we", "our", "us") values your privacy and is committed to ensuring that your personal information is protected. We do not collect any personal information from users through our website.`,
    },
    {
      title: "No Collection of Personal Information",
      body: `YUL7 ("we", "our", "us") values your privacy and is committed to ensuring that your personal information is protected. We do not collect any personal information from users through our website.`,
    },
    {
      title: "Contact Us",
      body: `YUL7 ("we", "our", "us") values your privacy and is committed to ensuring that your personal information is protected. We do not collect any personal information from users through our website.`,
    },
    {
      title: "Changes to This Policy",
      body: `YUL7 ("we", "our", "us") values your privacy and is committed to ensuring that your personal information is protected. We do not collect any personal information from users through our website.`,
    },
    {
      title: "Contact Information",
      body: `For any questions or concerns regarding this Privacy Policy, please contact us at yul7_official@naver.com.`,
    },
  ];
  return (
    <SiteInfoModal
      siteInformation={privacyPolicyData}
      headerText={"PRIVACY POLICY (개인정보처리방침)"}
      onCloseButtonClick={onCloseButtonClick}
      isOpen = {isOpen}
    />
  );
};

export default PrivacyPolicyModal;
