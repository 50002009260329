import "./ProductShowcaseMainBlock.css";
import ProductShowcaseAnimatedComponent from "../../components/ProductShowcaseAnimatedComponent/ProductShowcaseAnimatedComponent";
import MobileProductShowcase from "../../components/MobileProductShowcase/MobileProductShowcase";
import { useState, useEffect } from "react";

const ProductShowcaseMainBlock = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="product-showcase-main-block">
      <div className="psb-upper-text-container">
        <div className="psb-upper-text-header-container">
          <h1>YUL7 ROLLER PUFF</h1>
        </div>
        <div className="psb-upper-text-content-container">
          <p>Your secret for Natural, Flawless and Long-lasting base makeup</p>
        </div>
      </div>
      <div className="psb-animated-component-wrapper">
        <ProductShowcaseAnimatedComponent />
      </div>
      <div className="psb-main-mobile-container">
        <MobileProductShowcase />
      </div>
      <div className="psb-lower-text-container">
        <p>
          Experience the luxury of the Yul Seven Puff. Crafted with care by
          Korean artisans with over 30 years of experience, this puff offers
          smooth application and gentle care for your skin.
        </p>
      </div>
    </div>
  );
};

export default ProductShowcaseMainBlock;
