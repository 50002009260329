import PinkStartIcon from "../../assets/icon-components/PinkStartIcon/PinkStarIcon";
import "./NewTextIconCard.css";

const NewTextIconCard = ({ text, rightImage }) => {
  return (
    <div className="nti-container">
      <div className="nti-content">
        {/* <div className="nti-icon-container"> */}
        <div className={rightImage ? `nti-icon-container-right` : `nti-icon-container`}>
          <PinkStartIcon />
        </div>
        <div className="nti-text-container">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default NewTextIconCard;