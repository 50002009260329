import Yul7Logo from "../../assets/icon-components/YulLogo/Yul7Logo";
import "./FooterBlock.css";
import { footerImages } from "../../assets/footer-images";
import { useState, useEffect } from "react";

const FooterBlock = ({
  handleTermsAndConditionsClick,
  handlePrivacyPolicyClick,
  onScroll,
  refs
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.matchMedia("(max-width: 750px)").matches);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const getBackgroundImage = (desktopImage, mobileImage) => {
    return isMobile ? mobileImage : desktopImage;
  };

  const currentYear = new Date().getFullYear();
  return (
    <div
      className="yul7-footer-container"
      style={{ backgroundImage: `url(${getBackgroundImage(footerImages.footerDesktopImg, footerImages.footerMobileImg)})` }}
    >
      <div className="footer-logo-copyright-container" onClick={() => onScroll(refs)}  style={{cursor:"pointer"}}>
        <Yul7Logo width={96} height={33.49}/>
        <p>&copy; {currentYear} YUL7</p>
      </div>
      <div className="footer-navigation-container">
        <p onClick={handleTermsAndConditionsClick}>Terms & Conditions</p>
        <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
      </div>
    </div>
  );
};

export default FooterBlock;
