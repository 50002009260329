import MobileInstagramCard from "../MobileInstagramCard/MobileInstagramCard";
import "./InstagramMobileCards.css";

const InstagramMobileCards = ({ cardInformation }) => {
  return (
    <div className="instagram-mobile-cards-container">
      <div className="instagram-mobile-card-wrapper left-card">
        <MobileInstagramCard
          image={cardInformation[0].image}
          viewCount={cardInformation[0].viewCount}
          height={cardInformation[0].height}
          width={cardInformation[0].width}
          url={cardInformation[0].url}
          countLeft={true}
        />
      </div>
      <div className="instagram-mobile-card-wrapper center-card">
        <MobileInstagramCard
          image={cardInformation[1].image}
          viewCount={cardInformation[1].viewCount}
          height={cardInformation[1].height}
          width={cardInformation[1].width}
          url={cardInformation[1].url}
        />
      </div>
      <div className="instagram-mobile-card-wrapper right-card">
        <MobileInstagramCard
          image={cardInformation[2].image}
          viewCount={cardInformation[2].viewCount}
          height={cardInformation[2].height}
          width={cardInformation[2].width}
          url={cardInformation[2].url}
          countRight={true}
        />
      </div>
    </div>
  );
};

export default InstagramMobileCards;
