import React, { useState, useEffect } from "react";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import "./FeatureBlock.css";
import { newProductImages } from "../../assets/new-feature-block-images";
import ConnectingGreenLine from "../../assets/feature-block-images/ConnectingGreenLine/ConnectingGreenLine";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function FeatureBlock() {
  const initialFeatureList = [
    {
      image: newProductImages.productImage1,
      text: "Our innovative design makes it simple to replace the puff, ensuring your skin stays healthy and your products remain pure.",
      rightImage: false,
    },
    {
      image: newProductImages.productImage2,
      text: "Crafted from premium non-latex materials, this puff retains its shape and softness, wash after wash, ensuring lasting luxury.",
      rightImage: true,
    },
    {
      image: newProductImages.productImage3,
      text: "Designed for even the most sensitive skin, the Yul Seven Puff offers a cloud-like application and a flawless finish.",
      rightImage: false,
    },
  ];

  const [featureList, setFeatureList] = useState(initialFeatureList);
  const [firstLineVisible, setFirstLineVisible] = useState(false);
  const [secondLineVisible, setSecondLineVisible] = useState(false);

  const [ref1, inView1] = useInView({ threshold: 0.9, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.9, triggerOnce: true });
  const [ref3, inView3] = useInView({ threshold: 0.9, triggerOnce: true });

  useEffect(() => {
    if (inView1 && inView2) {
      setFirstLineVisible(true);
    }
  }, [inView1, inView2]);

  useEffect(() => {
    if (inView2 && inView3) {
      setSecondLineVisible(true);
    }
  }, [inView2, inView3]);

  const adjustRightImageForScreenSize = () => {
    if (window.innerWidth < 750) {
      setFeatureList((prevFeatureList) =>
        prevFeatureList.map((feature) => ({
          ...feature,
          rightImage: false,
        }))
      );
    } else {
      setFeatureList(initialFeatureList);
    }
  };

  useEffect(() => {
    adjustRightImageForScreenSize();
    window.addEventListener("resize", adjustRightImageForScreenSize);

    return () => {
      window.removeEventListener("resize", adjustRightImageForScreenSize);
    };
  }, []);

  return (
    <div className="feature-block-outer-wrapper">
      <div className="feature-block-greenlines-container">
        <ConnectingGreenLine 
          firstLineVisible={firstLineVisible} 
          secondLineVisible={secondLineVisible}
        />
      </div>
      <div className="feature_block">
        <div className="feature_cards_container">
          {featureList.map((item, index) => (
            <div ref={index === 0 ? ref1 : index === 1 ? ref2 : ref3} key={index}>
              <FeatureCard
                image={item.image}
                text={item.text}
                rightImage={item.rightImage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}