import React from "react";
import { motion } from "framer-motion";
import "./InstagramCard.css";
import PlayIcon from "../../assets/icon-components/PlayIcon/PlayIcon";

const InstagramCard = ({ image, viewCount, height, width, url }) => {
  const handleCardClick = () => {
    window.open(url, "_blank");
  };

  return (
    <div
      className="yul7_instagram_crd"
      style={{
        height,
        width,
        overflow: "hidden",
        position: "relative",
      }}
      onClick={handleCardClick}
    >
      <motion.div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
        }}
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 100, damping: 65, mass: 1 }}
      />
      <div 
        className="yul7_instagram-crd_view_count_container"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div className="yul7_instagram-crd_view_playbtn_container">
          <PlayIcon />
        </div>
        <div>
          <p className="yul7_instagram-crd_view_count_wrapper">{viewCount}M</p>
        </div>
      </div>
    </div>
  );
};

export default InstagramCard;