const HeartIcon = () => {
  return (
    <svg
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.021 2.50947C25.3553 1.83681 24.5649 1.3032 23.695 0.939139C22.8251 0.575078 21.8927 0.387695 20.9511 0.387695C20.0095 0.387695 19.0771 0.575078 18.2071 0.939139C17.3372 1.3032 16.5469 1.83681 15.8812 2.50947L15.6356 2.75751C15.0101 3.3893 13.9893 3.3893 13.3638 2.75751L13.1182 2.50947C11.7736 1.15137 9.94988 0.388399 8.04831 0.388399C6.14673 0.388399 4.32304 1.15137 2.97843 2.50947C1.63381 3.86757 0.878418 5.70954 0.878418 7.63019C0.878418 9.55083 1.63381 11.3928 2.97843 12.7509L4.35994 14.1463L11.6599 21.5194C13.2236 23.0989 15.7757 23.0989 17.3395 21.5194L24.6394 14.1463L26.021 12.7509C26.6869 12.0786 27.2152 11.2803 27.5757 10.4016C27.9361 9.523 28.1217 8.58125 28.1217 7.63019C28.1217 6.67912 27.9361 5.73737 27.5757 4.85874C27.2152 3.98011 26.6869 3.18182 26.021 2.50947Z"
        fill="#F17EAE"
      />
    </svg>
  );
};

export default HeartIcon;
