import "./GlowingImage.css";
import { newProductImages } from "../../assets/new-feature-block-images";

const GlowingImage = ({ image }) => {
  return (
    <div className="glowing-image-container">
      <div className="glowing-image-outer-image">
        <img src={image} alt="" />
        {/* <img src={newProductImages.productImages1} /> */}
      </div>
      <div className="glowing-image-top-image">
        <img src={image} alt="" />
        {/* <img src={newProductImages.productImages1} /> */}
      </div>
    </div>
  );
};

export default GlowingImage;