import "./App.css";
import ContactBlock from "./blocks/ContactBlock/ContactBlock";
import FeatureBlock from "./blocks/FeatureBlock/FeatureBlock";
import InstagramBlock from "./blocks/InstagramBlock/InstagramBlock";
import FooterBlock from "./blocks/FooterBlock/FooterBlock";
import YulNavigation from "./components/Navigation/YulNavigation/YulNavigation";
import MobileNavigationPopup from "./components/Navigation/MobileNavigationPopup/MobileNavigationBottomSheet";
import { useState, useRef, useCallback } from "react";
import LottieSecondSection from "./blocks/LottieSecondSection/LottieSecondSection";
import HeroBlock from "./blocks/HeroBlock/HeroBlock";
import PrivacyPolicyModal from "./components/Modals/PrivacyPolicyModal/PrivacyPolicyModal";
import TermsAndConditionModal from "./components/Modals/TermsAndConditionsModal/TermsAndConditionsModal";
import ProductShowcaseBlock from "./components/ProductShowcaseImage/ProductShowcaseImage";
import ProductShowcaseMainBlock from "./blocks/ProductShowcaseMainBlock/ProductShowcaseMainBlock";

const LandingPage = () => {
  // bottom sheet of mobile navigation handling
  const [isMobileBottomSheetOpen, setMobileBottomSheetOpen] = useState(false);

  const [isTermConditionsOpen, setIsTermConditionsOpen] = useState(false);
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const toggleNav = () => {
    setMobileBottomSheetOpen(!isMobileBottomSheetOpen);
  };

  const handlePrivacyModal = () => {
    setPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  };
  const handleTermsAndConditionModal = () => {
    setIsTermConditionsOpen(!isTermConditionsOpen);
  };

  // handling the singlepage navigation
  const brandRef = useRef(null);
  const productRef = useRef(null);
  const instagramRef = useRef(null);
  const contactRef = useRef(null);
  const homeRef = useRef(null);

  const scrollToBlock = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToBlockMobile = useCallback((ref) => {
    if (ref.current) {
      const yOffset = ref === contactRef ? -50 : 0;
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    setMobileBottomSheetOpen(false);
  }, []);

  const onCloseMobileNavigationBottomSheet = () => {
    setMobileBottomSheetOpen(false);
  };

  return (
    <>
      <div style={{ width: "100%" }} ref={homeRef}>
        <YulNavigation
          onBurgerButtonClick={toggleNav}
          scrollToBlock={scrollToBlock}
          refs={{ brandRef, productRef, instagramRef, contactRef, homeRef }}
        />
      </div>

      <HeroBlock />

      <div ref={brandRef} style={{ height: "1rem" }}></div>
      <LottieSecondSection />

      <div ref={productRef} style={{ height: "5rem" }}></div>
      <ProductShowcaseMainBlock />

      <FeatureBlock />

      <div ref={instagramRef} style={{ height: "2rem" }}></div>
      <InstagramBlock />

      <div ref={contactRef} className="contact-inst-devider"></div>
      <ContactBlock />

      <FooterBlock
        handlePrivacyPolicyClick={handlePrivacyModal}
        handleTermsAndConditionsClick={handleTermsAndConditionModal}
        refs={homeRef}
        onScroll={scrollToBlock}
      />
      <MobileNavigationPopup
        isOpen={isMobileBottomSheetOpen}
        refs={{ brandRef, productRef, instagramRef, contactRef, homeRef }}
        scrollToBlock={scrollToBlockMobile}
        onClose={onCloseMobileNavigationBottomSheet}
      />
      <PrivacyPolicyModal
        onCloseButtonClick={handlePrivacyModal}
        isOpen={isPrivacyPolicyOpen}
      />
      <TermsAndConditionModal
        onCloseButonClick={handleTermsAndConditionModal}
        isOpen={isTermConditionsOpen}
      />
    </>
  );
};

export default LandingPage;
