const YouTubeButtonHoverIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6812 9.60002C31.6812 9.60002 31.3688 7.39377 30.4062 6.42502C29.1875 5.15002 27.825 5.14377 27.2 5.06877C22.725 4.74377 16.0063 4.74377 16.0063 4.74377H15.9937C15.9937 4.74377 9.275 4.74377 4.8 5.06877C4.175 5.14377 2.8125 5.15002 1.59375 6.42502C0.63125 7.39377 0.325 9.60002 0.325 9.60002C0.325 9.60002 0 12.1938 0 14.7813V17.2063C0 19.7938 0.31875 22.3875 0.31875 22.3875C0.31875 22.3875 0.63125 24.5938 1.5875 25.5625C2.80625 26.8375 4.40625 26.7938 5.11875 26.9313C7.68125 27.175 16 27.25 16 27.25C16 27.25 22.725 27.2375 27.2 26.9188C27.825 26.8438 29.1875 26.8375 30.4062 25.5625C31.3688 24.5938 31.6812 22.3875 31.6812 22.3875C31.6812 22.3875 32 19.8 32 17.2063V14.7813C32 12.1938 31.6812 9.60002 31.6812 9.60002ZM12.6938 20.15V11.1563L21.3375 15.6688L12.6938 20.15Z"
        fill="#FEF2EC"
      />
    </svg>
  );
};

export default YouTubeButtonHoverIcon;
