const PlayIcon = ({height = 24, width = 24}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07039 5.7894C7.73718 4.99935 6.05078 5.96027 6.05078 7.50998V16.49C6.05078 18.0397 7.73718 19.0007 9.07039 18.2106L16.6473 13.7206C17.9545 12.946 17.9545 11.054 16.6473 10.2794L9.07039 5.7894Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PlayIcon;
