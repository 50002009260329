import React from "react";

const PinkStartIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C12.2343 6.5279 17.4721 11.7657 24 12C17.4721 12.2343 12.2343 17.4721 12 24C11.7657 17.4721 6.5279 12.2343 0 12C6.5279 11.7657 11.7657 6.5279 12 0Z"
        fill="#F17EAE"
      />
    </svg>
  );
};

export default PinkStartIcon;
